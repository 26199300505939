<template>
    <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
        <v-col class="text-start mt-5 mb-3">
            <v-alert text dense color="#8061c2" border="left" class="pl-5">
                <h1 class="title mt-1">Ganancias Referidos</h1>
                <p class="desc mt-0 mb-1">Listado de las ganancias de los referidos.</p>
            </v-alert>
        </v-col>
        <v-row class="mx-3">
            <v-col cols="12" md="4" class="px-0">
                <v-text-field v-model="search" color="#8061c2" label="Buscar usuarios" append-icon="mdi-magnify"
                    single-line hide-details outlined class="mb-2 mx-0 px-0" @input="onSearch"></v-text-field>
            </v-col>

            <!-- Select para ordenar por campo -->
            <v-col cols="12" md="4" class="px-md-2 px-0">
                <v-select v-model="orderBy" :items="orderByOptions" label="Ordenar por" outlined
                    @change="onOrderChange"></v-select>
            </v-col>

            <!-- Select para dirección de ordenamiento -->
            <v-col cols="12" md="4" class="px-0">
                <v-select v-model="orderDirection" :items="orderDirectionOptions" label="Dirección" outlined
                    @change="onOrderChange"></v-select>
            </v-col>
        </v-row>
        <v-data-table v-show="!$route.query.details" class="elevation-1 mx-3 px-2 py-4" :headers="headers"
            :items="getUsersReferralsAdmin" :loading="loadingEarningsReferrals" :items-per-page="perPage"
            :page.sync="page" @update:page="onPageChange" @update:items-per-page="changePerPage"
            :footer-props="{ itemsPerPageOptions: [50, 100, 200, 300, -1], showFirstLastPage: true }">


            <template v-slot:item.detail="{ item }">
                <v-btn icon color="#8061c2" dark @click="goToDetails(item.create)">
                    <v-icon>mdi-eye</v-icon>
                </v-btn>
            </template>


        </v-data-table>

        <v-data-table v-show="$route.query.details" class="elevation-1 mx-3 px-2 py-4" :headers="headersDetail"
            :items="getUsersReferralsAdminDetail" :loading="loadingEarningsReferrals" :items-per-page="perPage"
            :page.sync="pageDetail" @update:page="onPageChangeDetail" @update:items-per-page="changePerPageDetail"
            :server-items-length="totalDetails"
            :footer-props="{itemsPerPageOptions: [50, 100, 200, 300, -1],showFirstLastPage: true}">

            <template v-slot:item.total_amount="{ item }">
                {{ formatDecimal(item.total_amount) }}
            </template>



        </v-data-table>
    </v-col>
</template>

<script>
import axios from "@/axios.js";
export default {
    data() {
        return {
            headers: [
                { text: 'Creador', value: 'create', align: 'start', sortable: false },
                { text: 'Código', value: 'owner_code', align: 'start' },
                { text: 'Referidos', value: 'total_referidos' },
                { text: 'Ganancias', value: 'total_earnings' },
                { text: 'Detalle', value: 'detail' }
            ],
            headersDetail: [
                { text: 'Creador', value: 'create', align: 'start', sortable: false },
                { text: 'Código', value: 'code', align: 'start' },
                { text: 'Referido', value: 'email' },
                { text: 'Sitio', value: 'site_name' },
                { text: 'Ganancias', value: 'total_amount' },
            ],
            getUsersReferralsAdmin:[],
            getUsersReferralsAdminDetail:[],
            search:'',
            loadingEarningsReferrals:false,
            orderBy: 'total_amount',
            orderDirection: 'desc',
            orderByOptions: [
                { text: 'Ganancias', value: 'total_amount' },
            ],
            orderDirectionOptions: [
                { text: 'Mayor a menor', value: 'desc' },
                { text: 'Menor a mayor', value: 'asc' },
            ],
            page: 1,
            pageDetail: 1,
            perPage: 50,
            totalUsers: 0,
            totalDetails: 0,
            initialLoad: true,
        }
    },
    watch: {
        orderBy(newVal, oldVal) {
            if (newVal !== oldVal && !this.initialLoad) {
                this.page = 1;
                if (this.$route.query.details) {
                    this.getUsersReferralsDetail();
                } else {
                    this.getUsersReferrals();
                }
            }
        },
        orderDirection(newVal, oldVal) {
            if (newVal !== oldVal && !this.initialLoad) {
                this.page = 1;
                if (this.$route.query.details) {
                    this.getUsersReferralsDetail();
                } else {
                    this.getUsersReferrals();
                }
            }
        },
        '$route.query.details'(newVal) {
            if (!newVal) {
                this.search = '';
                this.getUsersReferrals();
            }
        }
    },
    created(){
        this.initializeSearch();
        if(this.$route.query.details){
            this.getUsersReferralsDetail();
        }else{
            this.getUsersReferrals();
        }
    },
    methods: {
        async getUsersReferrals(){
            this.loadingEarningsReferrals = true;
            try {
            const response = await axios.get('/admin/get-users-earnings-admin', {
                params: {
                    page: this.page,
                    per_page: this.perPage,
                    search: this.search,
                    order_by: this.orderBy,
                    order_direction: this.orderDirection,
                }
            });
            this.getUsersReferralsAdmin = response.data.getUsersReferralsAdmin.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.loadingEarningsReferrals = false;
                this.initialLoad = false;
            }
        },

        async getUsersReferralsDetail(){
            this.loadingEarningsReferrals = true;
            try {
            const response = await axios.get('/admin/get-users-earnings-admin-detail', {
                params: {
                    page: this.pageDetail,
                    per_page: this.perPage,
                    search: this.search,
                    order_by: this.orderBy,
                    order_direction: this.orderDirection,
                }
            });
            this.totalDetails = response.data.getUsersReferralsAdmin.total;
            this.getUsersReferralsAdminDetail = response.data.getUsersReferralsAdmin.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.loadingEarningsReferrals = false;
                this.initialLoad = false;
            }
        },
        
        initializeSearch() {
            const searchQuery = this.$route.query.search;
            if (searchQuery) {
                this.search = searchQuery;
            }
        },

        goToDetails(email) {
            const routeData = this.$router.resolve({
                name: 'EarningsReferrals',
                query: { search: email, details: true }
            });

            window.open(routeData.href, '_blank');
        },

        onSearch() {
            this.page = 1;
            if (this.$route.query.details) {
            this.getUsersReferralsDetail();
            } else {
            this.getUsersReferrals();
            }
        },
        onOrderChange() {
            this.page = 1;
            if (this.$route.query.details) {
            this.getUsersReferralsDetail();
            } else {
            this.getUsersReferrals();
            }
        },
        onPageChange() {
            if (this.$route.query.details) {
            this.getUsersReferralsDetail();
            } else {
            this.getUsersReferrals();
            }
        },
        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1;
            this.getUsersReferrals(); 
        },

        onPageChangeDetail() {
            this.page = 1;
            this.getUsersReferralsDetail();
        },

        onSearchDetail() {
            this.page = 1;
            this.getUsersReferralsDetail();
        },

        changePerPageDetail(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1;
            this.getUsersReferralsDetail();
        },
        formatDecimal(value) {
            // Verificar si el valor tiene más de dos decimales
            const parts = value.toString().split('.');

            if (parts.length === 2 && parts[1].length > 2) {
            // Si tiene más de dos decimales, cortarlo a los primeros dos sin redondear
            return parseFloat(parts[0] + '.' + parts[1].slice(0, 2));
            }

            // Si tiene dos o menos decimales, devolver el valor original
            return value;
        },


    }
}
</script>

<style scoped>
.clickable-avatar:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

.title {
  font-size: 22px !important;
  color: #8061c2;
}

.desc {
  color: #737373;
  font-size: 15px;
}
</style>